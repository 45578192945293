import { trackingApi } from "../api/tracking_api";

export enum StorageKeys {
	accessToken = "accessToken",
	refreshToken = "refreshToken",
	installId = "installId",
	answers = "answers",
	experience = "experience",
	wellbeing = "wellbeing",
	name = "name",
	email = "email",
	preference = "preference",
	reasonId = "reasonId",
	reasonMsg = "reasonMsg",
	activeProductId = "activeProductId",
	activeSubscriptionId = "activeSubscriptionId",
	subscriptionTime = "subscriptionTime",
	sessionNumber = "sessionNumber",
	uuid = "uuid",
}

class LocalStorageService {
	get(key: StorageKeys): string | null {
		try {
			return localStorage.getItem(this.getKey(key));
		} catch (e) {
			// console.error("Error parsing get from localStorage", e);
			return null;
		}
	}

	set(key: StorageKeys, value: string): void {
		try {
			localStorage.setItem(this.getKey(key), value);
		} catch (e) {
			// console.error("Error set to localStorage", e);
		}
	}

	remove(key: StorageKeys): void {
		try {
			localStorage.removeItem(this.getKey(key));
		} catch (e) {
			// console.error("Error remove from localStorage", e);
		}
	}

	clear(): void {
		try {
			localStorage.clear();
		} catch (e) {
			// console.error("Error clear from localStorage", e);
		}
	}

	private getKey(key: string): string {
		return `${key}_${trackingApi.funnelId}`;
	}
}

const storage = new LocalStorageService();

export default storage;
