import { v4 as uuidv4 } from "uuid";
import axios, {
	AxiosError,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios";
import storage, { StorageKeys } from "../local_storage";
import { IRegisterResponse } from "./user_api";
import Postman, { EventsType } from "~/components/manage/postman";

export const api = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	timeout: 60 * 1000,
});

api.interceptors.request.use((request: InternalAxiosRequestConfig) => {
	let installId = storage.get(StorageKeys.installId);
	if (!installId) {
		installId = uuidv4();
		storage.set(StorageKeys.installId, installId);
	}

	request.headers.set({
		"X-Language": "1",
		"X-Platform": "web",
		"X-InstallId": installId,
	});

	const token = storage.get(StorageKeys.accessToken);
	if (token) {
		request.headers.set("Authorization", `Bearer ${token}`);
	}

	return request;
});

api.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async function (error: AxiosError) {
		const originalRequest = error.config;
		if (
			originalRequest &&
			error.response?.status == 401 &&
			!originalRequest.headers.Retry
		) {
			const accessToken = await refreshAccessToken();
			if (!accessToken) {
				console.warn(error);
				return;
			}

			originalRequest.headers.Authorization = `Bearer ${accessToken}`;
			originalRequest.headers.Retry = "true";
			return api(originalRequest);
		}
		return Promise.reject(error);
	}
);

async function refreshAccessToken(): Promise<string | undefined> {
	const refreshToken = storage.get(StorageKeys.refreshToken);
	if (!refreshToken) return;
	try {
		Postman(EventsType.Debug, "refresh token");
		const { data } = await api.get<IRegisterResponse>(
			"/identity-api/token/refresh",
			{
				params: {
					refreshToken,
				},
			}
		);

		if (data.accessToken) {
			storage.set(StorageKeys.accessToken, data.accessToken);
			storage.set(StorageKeys.refreshToken, data.refreshToken);
			Postman(EventsType.Token, data);
			return data.accessToken;
		}
	} catch (e) {
		console.warn(e);
	}
}
