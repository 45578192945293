import { api } from ".";
import { v4 as uuidv4 } from "uuid";
import storage, { StorageKeys } from "../local_storage";

class TrackingApi {
	get webFunnelUserId() {
		let id = storage.get(StorageKeys.uuid);

		if (!id) {
			id = uuidv4();
			storage.set(StorageKeys.uuid, id);
		}

		return id;
	}

	get funnelId() {
		if (typeof window == "undefined") return "quiz-1";

		const parts = window.location.pathname.split("/");
		return parts.find((part) => part.startsWith("quiz-")) || "quiz-1";
	}

	track(data: Record<string, any>) {
		api.post("/analytics-api/webFunnel/events", data);
	}
}

export const trackingApi = new TrackingApi();
